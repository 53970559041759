<template>
  <div>
    <v-row justify="center" class="mx-0 mb-8 pb-8 pl-md-8 pr-md-8">
      <!-- DESKTOP -->
      <v-col cols="12" class="mt-14 text-left mb-xl-margin-provision-btn pl-0">
        <h1 class="provision-margin__title">Provisiones de comisión</h1>
      </v-col>
      <!-- MOBILE -->
      <v-col
        style="text-align: left"
        cols="12"
        class="mt-7 pl-3 py-4 px-0 index-f-sm mb-sm-margin-provision-btn"
      >
        <div class="d-flex">
          <v-btn @click="$router.go(-1)" x-small class="mt-0 mb-2" fab icon>
            <img width="15" src="../../assets/icon/navigate_before.png" />
          </v-btn>
          <p class="text-title-1">
            <label
              style="padding-top: 5%"
              class="text-apps-blue-sm txt-capitalize"
            >
              {{ name }},
            </label>
            <label style="padding-top: 5%" class="text-apps-blue-sm">
              acá encontrará
            </label>
          </p>
        </div>
        <p style="text-align: left" class="text-apps-sm mb-1 ml-9">
          para adjuntar documento
        </p>
      </v-col>
      <!-- INFO -->
      <v-col v-if="btnShow" cols="12" class="text-left">
        <v-btn
          @click="go"
          color="#466be3"
          dark
          rounded
          class="provision-margin__update-button"
        >
          ACTUALIZAR TABLAS
        </v-btn>
      </v-col>
      <v-row class="mx-0">
        <v-col cols="11" sm="5" md="3" lg="3" xl="3" class="pl-0">
          <v-card elevation="0">
            <v-row justify="center" class="mt-2 mb-2">
              <v-col cols="4">
                <v-layout justify-center>
                  <div class="container-icon txt-center">
                    <img
                      class="mt-2"
                      src="./../../assets/icon/calendar-tick.svg"
                    />
                  </div>
                </v-layout>
              </v-col>
              <v-col cols="8" class="text-left pl-0">
                <p class="mb-0 mt-1 txt-small-month">Inicio de mes</p>
                <p class="mb-0 mt-1 txt-month">{{ initDayMonth }}</p>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="11" sm="5" md="3" lg="3" xl="3">
          <v-card elevation="0">
            <v-row class="mt-2 mb-2">
              <v-col cols="4">
                <v-layout justify-center>
                  <div class="container-icon txt-center">
                    <img
                      class="mt-2"
                      src="./../../assets/icon/calendar-remove.svg"
                    />
                  </div>
                </v-layout>
              </v-col>
              <v-col cols="8" class="text-left pl-0">
                <p class="mb-0 txt-small-month">Fin de mes</p>
                <p class="mb-0 txt-month">{{ finalDayMonth }}</p>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-col class="px-0" cols="12">
        <v-expansion-panels>
          <v-expansion-panel v-for="(item, i) in listCostCenter" :key="i">
            <v-expansion-panel-header
              @click="getDocument(item)"
              class="title-center-cost"
            >
              {{ item }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-left">
              <v-alert
                v-if="alert"
                dense
                text
                type="info"
                class="mx-4 text-center"
              >
                El centro de costos no tiene archivo de provisión
              </v-alert>
              <template v-else>
                <v-btn
                  color="#466be3"
                  class="mb-xl-margin-provision-btn"
                  text
                  @click="download()"
                >
                  <u> Descargue aquí el archivo de provisiones </u>
                </v-btn>

                <v-btn
                  color="#466be3"
                  text
                  @click="download()"
                  class="mb-sm-margin-provision-btn"
                >
                  <u> Descargue aquí </u>
                </v-btn>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-overlay :value="loading.all">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        {{ $translate("general.loading") }}
      </v-progress-circular>
    </v-overlay>

    <!-- DIALOG -->
    <v-dialog v-model="message.dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5 request-project">
          <v-layout justify-center>
            {{ message.title }}
          </v-layout>
        </v-card-title>
        <v-card-text class="request-txt-project">
          {{ message.sms }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";
export default {
  data() {
    return {
      origin: window.location.origin,
      apps: [],
      message: {
        dialog: false,
        title: "",
        sms: "",
        type: "",
        redirect: "",
      },
      loading: {
        all: false,
      },
      listProvisional: null,
      listCostCenter: [],
      token: "",
      name: "",
      infoToken: {
        document: "",
      },
      costCenter: "",
      show: true,
      alert: true,
      initDayMonth: "",
      finalDayMonth: "",
      btnShow: false,
      name: "Angie",
    };
  },
  methods: {
    download() {
      window.open(this.listProvisional.commission_provisions, "_blank");
    },

    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    getDocument(item) {
      if (this.show) {
        this.getProvisional(item);
        this.show = true;
      } else {
        this.show = false;
      }
    },
    getProvisional(name) {
      var data = {
        costCenter: name,
      };
      this.loading.all = true;
      Api.MarginCommisions()
        .getProvisional(data, this.token)
        .then((res) => {
          if (res.data.cod == 0) {
            this.alert = false;
            this.listProvisional = res.data.data;
          }
        })
        .catch((error) => {
          this.loadingAll = false;
          if (error.response.status == 404) {
            this.alert = true;
          }
        })
        .finally(() => {
          this.loading.all = false;
        });
    },
    getCostCenter() {
      this.loading.all = true;
      Api.MarginCommisions()
        .getCostCenter(this.token)
        .then((res) => {
          if (res.data.cod == 0) {
            this.listCostCenter = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
          this.message.dialog = true;
          this.message.sms = error.response.data.message;
          this.message.title = "Oops... hubo un error";
          setTimeout(() => {
            this.message.dialog = false;
          }, 3000);
        })
        .finally(() => {
          this.loading.all = false;
        });
    },
    go() {
      this.$router.push({ name: "updateProvisionCommissions" });
    },
  },
  created() {
    this.getToken();
    this.apps = JSON.parse(localStorage.getItem("apps"));
  },
  mounted() {
    this.getCostCenter();
    var date = new Date();
    var initDayMonthC = new Date(date.getFullYear(), date.getMonth(), 1);
    var finalDayMonthC = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.initDayMonth = initDayMonthC.toISOString().substr(0, 10);
    this.finalDayMonth = finalDayMonthC.toISOString().substr(0, 10);
    for (let i = 0; i < this.apps.length; i++) {
      if (this.apps[i].es_un_modulo == "true" && this.apps[i]._ID == "316") {
        this.btnShow = true;
      }
    }
  },
};
</script>
<style lang="less" scoped>
@import "./../../assets/css/main.less";
.mb-xl-margin-provision-btn {
  display: block;
}

.mb-sm-margin-provision-btn {
  display: none;
}
.txt-upload-commision {
  font-family: "RobotoMedium";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 25px;
  color: #353535;
}
.title-center-cost {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #353535;
}
.txt-small-month {
  font-family: "RobotoMedium";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #353535;
}
.txt-month {
  font-family: "RobotoMedium";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  color: #466be3;
}
.container-icon {
  text-align: center;
  background: #f2f4ff;
  border-radius: 200px;
  height: 60px;
  width: 60px;
}

.text-apps-blue-sm {
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: #466be3;
}
.text-apps-sm {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #626262;
  font-family: "RobotoRegular";
}

.provision-margin__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.provision-margin__update-button:hover {
  background-color: #324ca1 !important;
}

/* LAPTOP 4k */

@media (max-width: 1900px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
}

/* MOBILE L*/
@media (max-width: 767px) {
  .mb-xl-margin-provision-btn {
    display: none;
  }
  .mb-sm-margin-provision-btn {
    display: block;
    text-align: initial;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .mb-xl-margin-provision-btn {
    display: none;
  }
  .mb-sm-margin-provision-btn {
    display: block;
    text-align: initial;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .mb-xl-margin-provision-btn {
    display: none;
  }
  .mb-sm-margin-provision-btn {
    display: block;
    text-align: initial;
  }
}
</style>
